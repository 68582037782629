<template>
  <div>
    <div class="bg">
      <img src="../assets/imgs/uploads/Rectangle 61.png" alt="" style="width: 100%;">
      <div class="bgone">
        <p style="text-align: center;">Contact Us</p>
        <div style="font-size: 16px;text-align: center;">
          <p>Email: lotus@gigwi.com.hk</p>
          <p>whatsApp: +8618795968284</p>
        </div>
      </div>
    </div>
    <div style="margin-top: 90px;">
      <div style="font-weight: bold;font-size: 48px;display: flex;justify-content: center;">Do you have any question?
      </div>
      <div style="margin-top: 23px;display: flex;justify-content: center;">Please fill out the form, we will contact you
        as soon as possible.</div>
      <div style="display: flex;justify-content: center;">Or you can find an answer in FAQ .</div>
    </div>
    <div class="form">
      <div style="margin: 33px 0;font-weight: bold;font-size: 40px;display: flex;justify-content: center;">Send a
        Message</div>
      <div style="width: 744px;margin: 0 auto;">
        <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="auto">
          <div style="display: flex;justify-content: space-between;align-items: center;">
            <el-form-item prop="YouName" :rules="[
              { required: true, message: '请输入', trigger: 'blur' },]">
              <el-input v-model="dynamicValidateForm.YouName" placeholder="Name"></el-input>
            </el-form-item>
            <el-form-item prop="YouEmail" :rules="[
              { required: true, message: '请输入邮箱地址', trigger: 'blur' },
              { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
            ]">
              <el-input v-model="dynamicValidateForm.YouEmail" placeholder="E-mail"></el-input>
            </el-form-item>
          </div>
          <el-input type="textarea" :rows="11" v-model="feedback" placeholder="Type your message here..."></el-input>
          <div style="margin-top: 30px;display: flex;justify-content: space-around;">
            <div style="display: flex;">
              <div @click="refreshCode()">
                <!--验证码组件-->
                <SecurityCode :identifyCode="identifyCode"></SecurityCode>
              </div>
              <input class="int" type="text" v-model="inputCode" :placeholder="$t('login.placeholder2')" />
            </div>
            <div class="btn1"><el-button @click="submitFeedback('dynamicValidateForm')">SUBMIT</el-button></div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import SecurityCode from "../components/SecurityCode.vue";
export default {
  components: {
    SecurityCode
  },
  computed: {

  },
  data() {
    return {
      userInfo: null,
      enquiry: '',
      feedback: '',
      YouName: '',
      YouEmail: '',
      identifyCodeType: "1234567890", //定义验证类型 1.数字 2.字母
      identifyCode: "",
      inputCode: "", //text框输入的验证码
      dynamicValidateForm: {
        YouName: '',
        YouEmail: '',
      }
    }
  },
  mounted() {
    this.refreshCode();
    // 获取 localStorage 中的用户信息
    const storedUserInfo = localStorage.getItem('user');
    // 判断是否存在用户信息
    if (storedUserInfo) {
      this.userInfo = JSON.parse(storedUserInfo);
    }
  },
  methods: {
    successed() {
      if (this.feedback) {
        this.$message({
          message: 'Message sent successfully!',
          showClose: true,
          type: 'success',
          center: true
        });
        this.feedback = ''
      }
    },
    submitFeedback(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 构建包含用户名和反馈信息的对象
          const data = {
            memberId: this.userInfo ? this.userInfo.id : '',
            feedback: this.feedback,
            memberName: this.dynamicValidateForm.YouName,
            email: this.dynamicValidateForm.YouEmail
          };
          if (this.feedback == "") {
            this.$message({
              message: this.$t('contactus.content'),
              type: "warning",
            });
            return;
          }
          if (this.inputCode == "") {
            this.$message({
              message: this.$t('contactus.verification'),
              type: "warning",
            });
            return;
          }
          if (this.identifyCode !== this.inputCode) {
            this.inputCode = "";
            this.$message.error(this.$t('contactus.verify'));
          } else {
            this.inputCode = ''
            // 使用axios发送POST请求
            axios.post('/api/mall/customer/feedback', data)
            this.successed()
          }
        } else {
          return false;
        }
      });
      this.refreshCode()
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    //初始化验证码
    refreshCode() {
      this.identifyCode = ""; //输入框置空
      this.makeCode(this.identifyCodeType, 4); //验证码长度为4
    },
    //随机切换验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodeType[
          this.randomNum(0, this.identifyCodeType.length)
          ];
      }
    },
  }
};
</script>
<style scoped>
.bg {
  position: relative;
  background-image: url('../assets/imgs/uploads/Rectangle 62.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bgone {
  font-size: 48px;
  font-weight: bold;
  color: #FFF;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form {
  width: 950px;
  height: 603px;
  margin: 55px auto 40px;
  overflow: hidden;
  border-radius: 20px;
  background-color: #F8F8F8;
}

.contactUs {
  font-size: 30px;
  color: #F09285;
  border-bottom: 3px solid #F09285;
  margin-bottom: 60px;
}

.msg {
  flex: 1;
  height: 45px;
  line-height: 45px;
  margin-left: 10px;
  color: #a3a3a3;
  border: 1px solid #edecec;
}

.enquiry {
  height: 250px;
  margin-left: 10px;
  margin-top: 20px;
  padding: 10px;
  color: #a3a3a3;
  border: 1px solid #edecec;
  display: flex;
}

::v-deep .el-textarea__inner {
  border: none;
  outline: none;
}

::v-deep .form .el-input__inner {
  width: 359px;
  height: 62px;
  border-radius: 15px;
  padding-left: 24px;
  background-color: #EAEAEA;
  border-color: #DCDFE6;
}

::v-deep .el-textarea__inner {
  resize: none;
  border-radius: 15px;
  padding: 24px;
  background-color: #EAEAEA;
}

.int {
  width: 180px;
  margin-left: 10px;
  height: 53px;
  padding-left: 24px;
  border-radius: 15px;
  background-color: #EAEAEA;
}

::v-deep .btn1 .el-button {
  width: 175px;
  height: 53px;
  font-size: 16px;
  color: #FFF;
  background-color: #000;
  border-radius: 35px;
  border: none;
}

::v-deep .btn1 .el-button:hover {
  background-color: #000;
}
</style>
