var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"form"},[_c('div',{staticStyle:{"margin":"33px 0","font-weight":"bold","font-size":"40px","display":"flex","justify-content":"center"}},[_vm._v("Send a Message")]),_c('div',{staticStyle:{"width":"744px","margin":"0 auto"}},[_c('el-form',{ref:"dynamicValidateForm",attrs:{"model":_vm.dynamicValidateForm,"label-width":"auto"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('el-form-item',{attrs:{"prop":"YouName","rules":[
            { required: true, message: '请输入', trigger: 'blur' },]}},[_c('el-input',{attrs:{"placeholder":"Name"},model:{value:(_vm.dynamicValidateForm.YouName),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "YouName", $$v)},expression:"dynamicValidateForm.YouName"}})],1),_c('el-form-item',{attrs:{"prop":"YouEmail","rules":[
            { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
          ]}},[_c('el-input',{attrs:{"placeholder":"E-mail"},model:{value:(_vm.dynamicValidateForm.YouEmail),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "YouEmail", $$v)},expression:"dynamicValidateForm.YouEmail"}})],1)],1),_c('el-input',{attrs:{"type":"textarea","rows":11,"placeholder":"Type your message here..."},model:{value:(_vm.feedback),callback:function ($$v) {_vm.feedback=$$v},expression:"feedback"}}),_c('div',{staticStyle:{"margin-top":"30px","display":"flex","justify-content":"space-around"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{on:{"click":function($event){return _vm.refreshCode()}}},[_c('SecurityCode',{attrs:{"identifyCode":_vm.identifyCode}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputCode),expression:"inputCode"}],staticClass:"int",attrs:{"type":"text","placeholder":_vm.$t('login.placeholder2')},domProps:{"value":(_vm.inputCode)},on:{"input":function($event){if($event.target.composing)return;_vm.inputCode=$event.target.value}}})]),_c('div',{staticClass:"btn1"},[_c('el-button',{on:{"click":function($event){return _vm.submitFeedback('dynamicValidateForm')}}},[_vm._v("SUBMIT")])],1)])],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/imgs/uploads/Rectangle 61.png"),"alt":""}}),_c('div',{staticClass:"bgone"},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Contact Us")]),_c('div',{staticStyle:{"font-size":"16px","text-align":"center"}},[_c('p',[_vm._v("Email: lotus@gigwi.com.hk")]),_c('p',[_vm._v("whatsApp: +8618795968284")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"90px"}},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"48px","display":"flex","justify-content":"center"}},[_vm._v("Do you have any question? ")]),_c('div',{staticStyle:{"margin-top":"23px","display":"flex","justify-content":"center"}},[_vm._v("Please fill out the form, we will contact you as soon as possible.")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_vm._v("Or you can find an answer in FAQ .")])])
}]

export { render, staticRenderFns }